import { Container, Nav, Navbar} from "react-bootstrap";
import "./Navigation.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function Navigation() {
    return(
        <Navbar collapseOnSelect expand="lg">
            <Container>
                <Navbar.Brand className="name" href="#home">Megan Provost</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link href="#">About</Nav.Link>
                        <Nav.Link href="#">My Work</Nav.Link>
                        <Nav.Link href="#"><i className="ri-linkedin-fill"></i></Nav.Link>
                        <Nav.Link href="#"><i className="ri-twitter-fill"></i></Nav.Link>
                        <Nav.Link href="#">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;