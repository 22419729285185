import Vanilla from './Cameron_Ken22_6580-1600x1066-c-default.jpg';
import Outdoors from './coming-out-outdoors.jpg';
import Timber from './timbersports.jpg';
import Cardinal from './daily-cardinal-1.jpg';
import TonyRobinson from './tony-robinson.jpg';
import Beer from './wisconsin-beer.jpg';
import './Articles.css';
import { Col, Row } from 'react-bootstrap';

const articles = [
    {
        'link': 'https://onwisconsin.uwalumni.com/features/for-the-love-of-vanilla/',
        'photo': Vanilla,
        'name': 'For the Love of Vanilla'
    },
    {
        'link': 'https://onwisconsin.uwalumni.com/features/coming-out-in-the-out-of-doors/',
        'photo': Outdoors,
        'name': 'Coming Out in the Out of Doors'
    },
    {
        'link': 'https://onwisconsin.uwalumni.com/contender/he-came-he-sawed-he-conquered/',
        'photo': Timber,
        'name': 'He Came, He Sawed, He Conquered'
    },
    {
        'link': 'https://www.dailycardinal.com/article/2018/02/eradicating-erasure-native-american-students-advocate-for-recognition-respect-of-culture-in-classroom',
        'photo': Cardinal,
        'name': 'Eradicating erasure: Native American students advocate for recognition, respect of culture in classroom'
    },
    {
        'link': 'https://www.dailycardinal.com/article/2017/02/family-of-tony-robinson-to-receive-3-35-million-in-settlement',
        'photo': TonyRobinson,
        'name': 'Family of Tony Robinson to receive $3.35 million in settlement'
    },
    {
        'link': 'https://www.dailycardinal.com/article/2018/04/bottoms-up-students-develop-real-world-expertise-in-beer-fermentation',
        'photo': Beer,
        'name': 'Bottoms up: Students develop real-world expertise in beer, fermentation'
    }
]

function ArticleRow(props) {
    let row = [];
    for (let i = props.start; i < props.start + 3; i++) {
        row.push(
            <Col sm={4} key={i} className="article" >
                <a href={articles[i].link} target="_blank" rel="noreferrer"> 
                    <img className="article-photo" src={articles[i].photo} alt={articles[i].name} />
                </a>
                <span className="article-title">{articles[i].name}</span>
            </Col>
        )
    }

    return row;
}

function ArticleGroup(){
    let articleGroup = [];
    for (let i = 0; i < articles.length; i += 3) {
        let rowName = "row-" + i;
        articleGroup.push(
            <Row key={rowName}>
                <ArticleRow start={i}></ArticleRow>
            </Row>
            
        )
    }

    return articleGroup;
}

function Articles() {
    return (
            <ArticleGroup/>
    );
}

export default Articles;