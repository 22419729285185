import './App.css';
import Navigation from './Navigation';
import Megan_Main from './megan_main.jpeg'
import Articles from './Articles';
import { Col, Container, Row, Image } from 'react-bootstrap';

function App() {
  return (
    <div className="App">
      <Navigation></Navigation>
      <div className="body">
        <Container className='profile'>
          <Row className='align-items-center'>
            <Col sm={6}>
              <Image fluid src={Megan_Main} alt="happy megan"/>
            </Col>
            <Col sm={6}>
              <div className='profile-text'>
                <span className='profile-name'>Megan Provost,</span>
                <br></br>
                <span className='profile-subtext'>Madison based writer looking to experience the world and make it a better place.</span>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className='articles'>
          <Row>
            <Col>
              <span className='work-title'>My Work</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Articles></Articles>
            </Col>
          </Row>
        </Container>
        <span>More about Me Soon!</span>
      </div>
    </div>
  );
}

export default App;
